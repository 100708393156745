
import { defineComponent, ref, defineAsyncComponent } from 'vue';
import { getGeotabAuth } from '@/helpers/get-geotab-auth';
import { geotabLoginRequest } from '@/api/accidentplan-api';
import { AuthModule } from '@/store/auth';

export default defineComponent({
  components: {
    GeotabSetupScreen: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "GeotabSetup" */ '@/components/geotab-setup/GeotabSetup.vue'
      ),
    ),
  },
  setup() {
    const showGeotabSetup = ref(false);

    getGeotabAuth().then(async geotabAuth => {
      try {
        const loginResp = await geotabLoginRequest(geotabAuth);

        AuthModule.login(() => loginResp);
      } catch (err) {
        if (err.error === 'ERR_GEOTAB_ACCOUNT_NOT_REGISTERED')
          showGeotabSetup.value = true;
      }
    });

    return { showGeotabSetup };
  },
});
