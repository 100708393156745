import { GeotabAuthDTO } from '@/api/dto/geotab.dto';

export async function getGeotabAuth() {
  const auth = await new Promise<GeotabAuthDTO>(res => {
    const windowEventListener = (event: MessageEvent) => {
      const data = event.data;

      console.log(`[CHILD] Received from ${event.origin}:`, data);

      if (data.client_id === 'mygeotab' && data.type === 'geotab-auth') {
        console.log('Geotab credentials:', data.message);
        window.removeEventListener('message', windowEventListener);
        res(data.message);
      }
    };

    window.addEventListener('message', windowEventListener);
    window.parent.postMessage(
      {
        client_id: 'accidentplan',
        type: 'request',
        message: {
          request: 'get-auth',
        },
      },
      '*',
    );
  });

  return auth;
}
